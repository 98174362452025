<template>
  <div class="setBase">
    <div>
      <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="压力级别" name="pressurelevel">
          <pressure-level v-if="activeName === 'pressurelevel'"></pressure-level>
        </el-tab-pane>
        <el-tab-pane label="管道确认状态" name="pipeStatus">
          <pipeStatus v-if="activeName === 'pipeStatus'"></pipeStatus>
        </el-tab-pane>
        <el-tab-pane label="管线状态" name="pipeline">
          <pipeline v-if="activeName === 'pipeline'"></pipeline>
        </el-tab-pane>
        <el-tab-pane label="管道规格" name="pipe">
          <pipe v-if="activeName === 'pipe'"></pipe>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Pipe from './components/pipe.vue'
import pipeStatus from './components/pipeStatus.vue'
import Pipeline from './components/pipeline.vue'
import PressureLevel from './components/pressurelevel.vue'
import './style/skin.scss'
export default {
  components: {
    Pipe,
    Pipeline,
    PressureLevel,
    pipeStatus
  },
  data() {
    return {
      activeName: 'pressurelevel'
    }
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .el-tabs--border-card>.el-tabs__header .el-tabs__item{color:#000}
}
.setBase {
  background: #fff;
  padding: 20px;
}
// ::v-deep .el-tabs--border-card>.el-tabs__header{
//   background-color: #fff;
// }
// ::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item{
//   color: #000;
//   font-weight: 400;
//   font-size: 16px;
// }
::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
   color: #409BFF;
   
}
</style>
